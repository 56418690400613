"use strict";
var _a;
exports.__esModule = true;
exports.DEFAULT_FEATURES = void 0;
var types_1 = require("../types");
exports.DEFAULT_FEATURES = (_a = {},
    _a[types_1.Feature.NoArcadeBranding] = false,
    _a[types_1.Feature.Analytics] = false,
    _a[types_1.Feature.PublishEventsToHost] = false,
    _a[types_1.Feature.DisableIPTracking] = false,
    _a[types_1.Feature.PreventIndexing] = false,
    _a[types_1.Feature.DoNotTrack] = false,
    _a[types_1.Feature.DisableInternal3rdPartyTrackers] = false,
    _a[types_1.Feature.PaywallArcadeLimit] = 3,
    _a[types_1.Feature.FlowInsights] = false,
    _a[types_1.Feature.EditAccessToArcadesIsControlled] = false,
    // Temporary features:
    _a[types_1.Feature.RemotionExport] = false,
    _a);
